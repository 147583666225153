import React from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import './modal.scss'

const ServiceModal = ({ isOpen, toggle, project }) => {
  return (
      <Modal size="lg" isOpen={isOpen} toggle={toggle} centered>
        <ModalBody isOpen={isOpen} toggle={toggle} >
          <ModalHeader toggle={toggle}>{project?.title}</ModalHeader>
          <ModalBody className="modal-wrapper" style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
            <div>
              {project?.description}
              <ul className="modal-points">
                {project?.points.map((point) => {
                  return <li>{point}</li>
                })}
              </ul>
            </div>
            <div>
              <img
                style={{ width: '100%' }}
                src={project?.img.src[0]}
                alt={project?.title}
              />
            </div>
        </ModalBody>
          <ModalFooter style={{ justifyContent: 'flex-start' }}>
            {/* TODO: return or delete buttons */}
             {/* <Button style={{ background: 'transparent', color: '#000', border: 'none' }} onClick={toggle}>Back</Button> */}
            <Button style={{ color: '#000', background: 'transparent', border: 'none', textDecoration: 'underline' }} onClick={toggle}>Zur Anmeldung</Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
  )
}

export default ServiceModal